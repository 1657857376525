export default {
  namespaced: true,
  state: {
    queues: {},
  },
  mutations: {
    // 入队
    ENQUEUE(state, { queueName, message }) {
      if (!state.queues[queueName]) {
        state.queues[queueName] = [];
      }
      state.queues[queueName].push(message);
    },
    // 出队，删除第一个消息
    DEQUEUE(state, queueName) {
      if (state.queues[queueName] && state.queues[queueName].length > 0) {
        state.queues[queueName].shift();
      }
    },
  },
  actions: {
    // 入队
    enqueue({ commit }, { queueName, message }) {
      commit('ENQUEUE', { queueName, message });
    },
    // 出队
    dequeue({ commit }, queueName) {
      commit('DEQUEUE', queueName);
    },
  },
  getters: {
    // 获取指定队列
    getQueue: (state) => (queueName) => state.queues[queueName] || [],
    // 获取指定队列的第一个消息
    nextMessage: (state) => (queueName) => state.queues[queueName]?.[0],
  },
};