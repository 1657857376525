import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { Notification } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/IndexPage.vue"),
    redirect: "/home",
    children: [
      { path: "/home", component: () => import("@/views/home/HomePage.vue") },
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/views/home/ChatPage.vue"),
      },
      {
        path: "/bot",
        component: () => import("@/views/home/BotPage.vue"),
      },
      {
        path: "/knowledge-base",
        component: () => import("@/views/home/KnowledgeBasePage.vue"),
      },
      {
        path: "/knowledge-base-detail/:code",
        component: () => import("@/views/home/KnowledgeBaseDetailPage.vue"),
      },
      {
        path: "/chat-list",
        component: () => import("@/views/home/ChatListPage.vue"),
      },
      {
        path: "/bot-list",
        component: () => import("@/views/home/BotListPage.vue"),
      },
      {
        path: "/account",
        component: () => import("@/views/user/AcountPage.vue"),
      },
      {
        path: "/settings",
        component: () => import("@/views/settings/SettingsPage.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
];

const router = new VueRouter({
  routes,
  allowDuplicateParams: true,
});

// 路由守护
router.beforeEach((to, from, next) => {
  const token = store.state.user.token; // 获取 token
  if (to.path !== "/login" && !token) {
    // 如果没有 token 并且目标路径不是登录页，重定向到登录页
    Notification.warning({
      message: "请登录",
      duration: 3000, // 持续时间
    });
    next({ path: "/login" });
  } else {
    // 否则，继续导航
    next();
  }
});

export default router;
