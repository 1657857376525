export default {
  namespaced: true,
  state: {
    sockets: {},
  },
  mutations: {
    ADD_SOCKET(state, { name, socket }) {
      state.sockets[name] = socket;
    },
    REMOVE_SOCKET(state, name) {
      if (state.sockets[name]) {
        state.sockets[name].close();
        delete state.sockets[name];
      }
    },
  },
  actions: {
    addSocket({ commit, dispatch }, { name, url }) {
      const socket = new WebSocket(url);
      console.log(socket)

      socket.onopen = () => {
        // console.log(`Socket ${name} connected`);
      };

      socket.onmessage = (event) => {
        // console.log(`Socket ${name} message:`, event.data);
        let data = JSON.parse(event.data)
        dispatch('queue/enqueue', { queueName: name, message: data }, { root: true });
      };

      socket.onclose = () => {
        // console.log(`Socket ${name} disconnected`);
        commit('REMOVE_SOCKET', name);
      };

      socket.onerror = () => {
        // console.error(`Socket ${name} error:`, error);
        commit('REMOVE_SOCKET', name);
      };

      commit('ADD_SOCKET', { name, socket });
    },
    removeSocket({ commit }, name) {
      commit('REMOVE_SOCKET', name);
    },
    sendMessage({ state }, { name, message }) {
      const socket = state.sockets[name];
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      }
    },
  },
};