import Vue from 'vue'
import Vuex from 'vuex'
import chat from './modules/chat'
import header from './modules/header'
import websocket from './modules/websocket'
import queue from './modules/queue'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  //   count: 0
  // },
  // getters: {

  // },
  // mutations: {

  // },
  // actions: {

  // },
  modules: {
    chat,
    header,
    websocket,
    queue,
    user,
  }
})
