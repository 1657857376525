export default {
  namespaced: true,
  state: {
    title: '',
    collapse: false,
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setCollapse(state, collapse) {
      state.collapse = collapse;
    },
  },
  actions: {
  },
};
