export default {
  namespaced: true,
  state: {
    userInfo: undefined,
    token: undefined,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = undefined;
    },
    setUserinfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    clearUserInfo(state) {
      state.userInfo = undefined;
    },
  },
  actions: {
    logout(context) {
      context.commit('clearToken');
      context.commit('clearUserInfo');
      // localStorage.removeItem('token');
    }
  },
};
