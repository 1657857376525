import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import store from './store/index'
import './common/style/reset.css'
import './common/style/base.scss'
import 'tailwindcss/tailwind.css'
import '@/common/css/tailwind.css'
import 'default-passive-events'
import * as radash from 'radash'
import * as lodash from 'lodash'

Vue.prototype.$radash = radash
Vue.prototype.$lodash = lodash

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
